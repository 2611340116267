import React from "react";
import { Link } from "react-router-dom";

const FooterOne = () => {
  return (
    <>
      {/* Footer area start */}
      <footer className="footer__area">
        <div className="footer__top">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="footer__map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3471.910331304133!2d-95.74022942446622!3d29.518972775192328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86411d7bb5b38963%3A0x645538175a764331!2s7207%20Dawn%20Vw%20Ln%2C%20Rosenberg%2C%20TX%2077469%2C%20USA!5e0!3m2!1sen!2sin!4v1719330505795!5m2!1sen!2sin"
                    loading="lazy"
                    title="Ameri trade"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__widgets">
          <div className="container">
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                <div className="footer__logo">
                  <img
                    src="/assets/imgs/logo/logo.png"
                    alt="Footer Logo"
                  />
                  <p>
                  Smart Itech LLC excels in providing versatile data solutions and IT consultancy services, tailored to meet the diverse needs of various industries and projects.
                  </p>
                  <Link className="db-btn-arrow" to="/about">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </Link>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                <div className="footer__address">
                  <h2 className="footer__title">Address</h2>
                  <ul>
                    <li>
                      <span>
                        <i className="fa-solid fa-paper-plane" />
                      </span>
                      <p>
                      Smart Itech LLC <br/>
                      7207 Dawn view ln,<br />
                      Rosenberg, TX, 77469
                      </p>
                    </li>
                    <li>
                      <span>
                        <i className="fa-solid fa-phone" />
                      </span>
                      <Link to="tel: 281- 725-6257"> 281- 725-6257</Link>
                    </li>
                    <li>
                      <span>
                        <i className="fa-solid fa-envelope" />
                      </span>
                      <Link to="info@ameritradedatasolutions.com">
                      info@smartitech.com
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                <div className="footer__service">
                  <h2 className="footer__title">More Services</h2>
                  <ul>
                    <li>
                      <Link to="/about">DevOps Implementation Service
                      </Link>
                    </li>
                    <li>
                      <Link to="/about">Data & Artificial Intelligence
                      </Link>
                    </li>
                    <li>
                      <Link to="/about">Data Enrichment
                      </Link>
                    </li>
                    <li>
                      <Link to="/about">Customer Modeling
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                <div className="footer__newsletter">
                  <div className="footer__title">Newsletter</div>
                  <p>
                    It is a long established fact that a reader will be
                    distracted
                  </p>
                  <form action="#">
                    <input
                      type="email"
                      name="email"
                      placeholder="Your mail address"
                    />
                    <button type="submit" className="submit">
                      <i className="fa-solid fa-paper-plane" />
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-xxl-12">
                <div className="footer__btm">
                  <div className="footer__copyright">
                    <p>© Smart Itech LLC | All Rights Reserved</p>
                  </div>
                  <div className="footer__social">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-instagram" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer area end */}
    </>
  );
};

export default FooterOne;
