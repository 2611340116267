import React, { Suspense } from "react";
import Preloader from "../elements/Preloader";
import HeaderOne from "../components/HeaderOne";
import Breadcrumb from "../components/Breadcrumb";
import FooterFour from "../components/FooterOne";
import Industries from "../components/Industries";

const IndustriesWeFunctioned = () => {
  return (
    <>
      <Suspense fallback={<Preloader />}>
        <HeaderOne />
        <main>
          <Breadcrumb title="Industries We Serve" />
          <Industries />
        </main>
        <FooterFour />
      </Suspense>
    </>
  );
};

export default IndustriesWeFunctioned;
