import React, { Suspense } from "react";
import Preloader from "../elements/Preloader";
import HeaderOne from "../components/HeaderOne";
import HeroOne from "../components/HeroOne";
import ServiceOne from "../components/ServiceOne";
import CounterFive from "../components/CounterFive";
import BrandOne from "../components/BrandOne";
import FooterOne from "../components/FooterOne";
import TestimonialOne from "../components/TestimonialOne";

const HomeOne = () => {
  return (
    <>
      <Suspense fallback={<Preloader />}>
        <HeaderOne />
        <main>
          <HeroOne />
          <ServiceOne />
          <CounterFive />
          <TestimonialOne/>
          <BrandOne />
          
        </main>
        <FooterOne />
      </Suspense>
    </>
  );
};

export default HomeOne;
