import React, { useState } from "react";

const industriesData = {
  Healthcare: {
    title: "Healthcare & Life Sciences Industry",
    image: "assets/imgs/industries/a.jpg",
    content: `
    Our Healthcare & Life Sciences Industry Email List is one of the largest in the market, verified quarterly. Our database covers manufacturers, caregivers, and payers in the Healthcare/Medical Industry, including the Medical Devices, Pharmaceutical, and biotech sectors. With hundreds of specific job positions represented, you can be sure your offer will reach the right decision-makers/influencers. Our Medical Professionals database includes doctors from hospitals and private practices, with specialties ranging from Anesthesiology to Urology. Additionally, we have information on support personnel such as nurses and physician assistants.
    `,
  },
  Technology: {
    title: "Technology Industry Email List",
    image: "assets/imgs/industries/f.jpg",
    content: "The food and beverage industry is thriving, with consistent growth and promising projections for continued expansion due to innovative business models. Now is the perfect time to capitalize on global trends and solidify your company's growth and development. Whether you're a small business owner or a large corporation, investing in data and expanding your business is a strategic move. Grey Global B2B's Food Industry Marketing Database is here to assist.",
  },
  Construction: {
    title: "Construction Industry Mailing List",
    image: "assets/imgs/industries/b.jpg",
    content: "Successful construction projects rely on seamless cooperation across multiple disciplines, making it essential for marketers to engage with all key decision-makers and influencers within a construction firm.  Architect Construction Manager Construction Superintendent / Supervisor Survey Chief Estimators / Estimating Manager Project Engineer Contractors / Sub-Contractors Project Managers and Developers Design & Construction Director/ Manager Construction Materials and Plant Buyer We have an extensive reach, covering construction companies globally, regardless of size or type. Our database includes:  Residential, Commercial, and Heavy Construction Builders and Remodelers Plumbing, Heating, and Air-conditioning Roofing/Flooring or Siding Contractors Special Trade Contractors Connect with your Data Specialist today to address your construction marketing needs with confidence.",
  },
  "Energy Industry Mailing List": {
    title: "Energy Industry Mailing List",
    image: "assets/imgs/industries/c.jpg",
    content: "The Energy industry encompasses all sectors involved in the production and sale of energy, including fuel extraction, manufacturing, refining, and distribution. In today's world, energy is crucial, making the energy industry a fundamental part of every nation's infrastructure and maintenance.  Key segments of the Energy industry include:  Petroleum Business: Oil companies, refineries, fuel transportation, and end-user sales at gas stations. Gas Business: Natural gas extraction, coal gas manufacturing, distribution, and sales. Electrical Power Business: Electricity generation, distribution, and sales. Coal Mining Business: Involved in coal extraction and processing. Nuclear Energy Sector: Focused on nuclear power generation. Renewable Energy Business: Companies engaged in hydroelectric, wind, and solar power generation, as well as the manufacture, distribution, and sale of alternative fuels. The United States stands as a global leader in energy production and supply and is one of the largest energy consumers. The energy sector is the third largest in the United States, encompassing oil, natural gas, coal, nuclear power, renewable energy, and fuels, along with electricity, smart grid, and demand response systems.",
  },
  Automotive: {
    title: "Automotive Industry Mailing List",
    image: "assets/imgs/industries/d.jpg",
    content: `
The automotive industry includes a wide range of organizations involved in the design, manufacturing, marketing, and servicing of automobiles. It is a significant economic sector in terms of revenue. Grey Global Data offers top-quality Automotive Industry executive multi-channel lists tailored to the specific needs of clients. This includes lists for automobile manufacturers, body shop equipment manufacturers, parts manufacturers, repair services, dealers, accessories, insurance, and leasing. Contact your Data Specialist today to discuss your automotive marketing requirements.
    `,
  },
  Finance: {
    title: "Financial Services Industry Email List",
    image: "assets/imgs/industries/f.jpg",
    content: "Our clients gain significant advantages from the most extensive email reach available for their BFSI marketing campaigns, including targeted niche searches.  BFSI encompasses a wide range of financial institutions such as commercial banks, insurance companies, non-banking financial companies, cooperatives, pension funds, mutual funds, and smaller financial organizations.  Banking services include Core Banking, Retail Banking, Private Banking, Corporate Banking, Investment Banking, and Cards, among others.  Our list also includes stock brokerage, payment gateways, mutual funds, asset management, venture capital, and other financial services.  In the insurance sector, we cover both life (Living) and non-life (Non-Living) carriers and brokers.  To thrive amidst the challenges of mergers and evolving competitive forces, the BFSI sector requires a strategic blend of technology and service operations. Merely achieving cost savings through BPOs is no longer sufficient.  Partner with us today and leverage our expertise to confidently navigate and excel in the BFSI sector.",
  },
  FMCG: {
    title: "Food And Beverage Industry Email List",
    image: "assets/imgs/industries/g.jpg",
    content: "The food and beverage industry is thriving, with consistent growth and promising projections for continued expansion due to innovative business models. Now is the perfect time to capitalize on global trends and solidify your company's growth and development. Whether you're a small business owner or a large corporation, investing in data and expanding your business is a strategic move. Grey Global B2B's Food Industry Marketing Database is here to assist. Speak with your Data Specialist today about your Food and Beverage marketing needs.",
  },
};

const Industries = () => {
  const [selectedIndustry, setSelectedIndustry] = useState("Healthcare");

  return (
    <div className="container">
      <div className="industries__buttons">
        {Object.keys(industriesData).map((industry) => (
          <button
            key={industry}
            className={`industries__button ${
              selectedIndustry === industry ? "active" : ""
            }`}
            onClick={() => setSelectedIndustry(industry)}
          >
            {industriesData[industry].title}
          </button>
        ))}
      </div>
      <div className="service__details top pb-140">
        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-lg-6">
            <div className="img">
              <img
                src={industriesData[selectedIndustry].image}
                alt={industriesData[selectedIndustry].title}
              />
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6">
            <div className="content">
              <h2 className="service__details-title">
                {industriesData[selectedIndustry].title}
              </h2>
              <p>{industriesData[selectedIndustry].content}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Industries;
