import React from "react";
import { Link } from "react-router-dom";

const ServiceOne = () => {
  return (
    <>
      {/* Service area start */}
      <section className="service__area pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-xl-7 col-md-7">
              <div className="service__title-wrap" data-aos="fade-up">
                {/* <h2 className="sec-subtitle">
                  best service <span>best service</span>
                </h2> */}
                <h3 className="sec-title">
                  Innovative solutions for <br /> a digital world
                </h3>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-xl-5 col-md-5">
              <div
                className="service__text"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <p></p>
              </div>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-xxl-3 col-xl-3 col-md-6">
              <div className="service__item" data-aos="fade-up">
                <div className="icon">
                  <img src="assets/imgs/icon/s3.png" alt="Service Icon" />
                </div>
                <div className="service__content">
                  <Link to="/service">
                    <div className="service__title">
                      DevOps Implementation Service
                    </div>
                  </Link>
                  <p>
                    DevOps implementation is the cornerstone of aligning the
                    software development lifecycle with business objectives. We
                    drive organizational transformation by asking the right
                    questions and taking the most effective actions. Our DevOps
                    services include infrastructure management, automated
                    delivery pipelines, orchestration, deployment, and more.
                  </p>
                  <Link className="db-btn-arrow" to="/service">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-md-6">
              <div
                className="service__item"
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <div className="icon">
                  <img src="assets/imgs/icon/s1.png" alt="Service Icon" />
                </div>
                <div className="service__content">
                  <Link to="/service">
                    <div className="service__title">
                      Data & Artificial Intelligence
                    </div>
                  </Link>
                  <p>
                    AI is transforming industries, making it crucial for
                    businesses to embrace data modernization and AI innovation.
                    With 67% of organizations globally adopting AI, it’s time to
                    consider its impact. At Smart Itech LLC, we help
                    organizations harness data and AI to optimize processes,
                    accelerate decision-making, and enhance customer
                    experiences. This leads to increased productivity, greater
                    automation, and the discovery of new insights.
                  </p>
                  <Link className="db-btn-arrow" to="/service">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-md-6">
              <div
                className="service__item"
                data-aos="fade-up"
                data-aos-delay={1000}
              >
                <div className="icon">
                  <img src="assets/imgs/icon/s2.png" alt="Service Icon" />
                </div>
                <div className="service__content">
                  <Link to="/service">
                    <div className="service__title">Data Enrichment</div>
                  </Link>
                  <p>
                    Our Data Enrichment solutions empower you to gain deeper insights into your attendees, customers, and prospects, enabling more effective marketing and the identification of new prospects that closely resemble your existing customers. Smart Itech LLC can enrich your database by appending crucial information like contact names, job titles, email addresses, phone numbers, mailing addresses, business types, employee counts, and annual revenue.
                  </p>
                  <Link className="db-btn-arrow" to="/service">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-md-6">
              <div
                className="service__item"
                data-aos="fade-up"
                data-aos-delay={1500}
              >
                <div className="icon">
                  <img src="assets/imgs/icon/medal.png" alt="Service Icon" />
                </div>
                <div className="service__content">
                  <Link to="/service">
                    <div className="service__title">Customer Modeling</div>
                  </Link>
                  <p>
                   A significant portion of your revenue likely comes from a select group of clients. By analyzing the industries these clients belong to, their job titles, annual revenue, company size, and spending patterns, Smart Itech LLC can help you identify similar companies and contacts. This allows you to tap into additional revenue-generating opportunities.
                  </p>
                  <Link className="db-btn-arrow" to="/service">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Service area end */}
    </>
  );
};

export default ServiceOne;
