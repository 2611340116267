import React from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";

const CounterFive = () => {
  return (
    <>
      {/* Counter area start */}
      <section className="counter__area-4 pt-120 pb-100">
        <div className="container">
          <div className="counter__inner-4">
            <div className="section-title" data-aos="fade-up">
              <h2 className="sec-counter">DATA SPECTRUM</h2>
            </div>
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-5">
                <div className="counter__item-4" data-aos="fade-up" data-aos-delay={200}>
                  <div className="counter__left-4">
                    <img src="assets/imgs/icon/c2.png" alt="Icon" />
                  </div>
                  <div className="counter__right-4">
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <>
                            <div className="counter__number-4 counter_slow">
                              <CountUp delay={0} start={0} end={12} />M
                            </div>
                            <h2 className="counter__title-4">Companies</h2>
                          </>
                        )
                      }
                    </TrackVisibility>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-5">
                <div className="counter__item-4" data-aos="fade-up" data-aos-delay={300}>
                  <div className="counter__left-4">
                    <img src="assets/imgs/icon/c2.png" alt="Icon" />
                  </div>
                  <div className="counter__right-4">
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <>
                            <div className="counter__number-4 counter_slow">
                              <CountUp delay={0} start={0} end={25} />M
                            </div>
                            <h2 className="counter__title-4">Contacts</h2>
                          </>
                        )
                      }
                    </TrackVisibility>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-5">
                <div className="counter__item-4" data-aos="fade-up" data-aos-delay={400}>
                  <div className="counter__left-4">
                    <img src="assets/imgs/icon/c2.png" alt="Icon" />
                  </div>
                  <div className="counter__right-4">
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <>
                            <div className="counter__number-4 counter_slow">
                              <CountUp delay={0} start={0} end={135} />
                            </div>
                            <h2 className="counter__title-4">Countries</h2>
                          </>
                        )
                      }
                    </TrackVisibility>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-5">
                <div className="counter__item-4" data-aos="fade-up" data-aos-delay={500}>
                  <div className="counter__left-4">
                    <img src="assets/imgs/icon/c2.png" alt="Icon" />
                  </div>
                  <div className="counter__right-4">
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <>
                            <div className="counter__number-4 counter_slow">
                              <CountUp delay={0} start={0} end={5000} />
                            </div>
                            <h2 className="counter__title-4">Events Per Year</h2>
                          </>
                        )
                      }
                    </TrackVisibility>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-5">
                <div className="counter__item-4" data-aos="fade-up" data-aos-delay={600}>
                  <div className="counter__left-4">
                    <img src="assets/imgs/icon/c2.png" alt="Icon" />
                  </div>
                  <div className="counter__right-4">
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <>
                            <div className="counter__number-4 counter_slow">
                              <CountUp delay={0} start={0} end={9000} />
                            </div>
                            <h2 className="counter__title-4">Data Sources</h2>
                          </>
                        )
                      }
                    </TrackVisibility>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-5">
                <div className="counter__item-4" data-aos="fade-up" data-aos-delay={700}>
                  <div className="counter__left-4">
                    <img src="assets/imgs/icon/c2.png" alt="Icon" />
                  </div>
                  <div className="counter__right-4">
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <>
                            <div className="counter__number-4 counter_slow">
                              <CountUp delay={0} start={0} end={250} />
                            </div>
                            <h2 className="counter__title-4">Data Scientists</h2>
                          </>
                        )
                      }
                    </TrackVisibility>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-5">
                <div className="counter__item-4" data-aos="fade-up" data-aos-delay={800}>
                  <div className="counter__left-4">
                    <img src="assets/imgs/icon/c2.png" alt="Icon" />
                  </div>
                  <div className="counter__right-4">
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <>
                            <div className="counter__number-4 counter_slow">
                              <CountUp delay={0} start={0} end={14} />
                            </div>
                            <h2 className="counter__title-4">data attributes</h2>
                          </>
                        )
                      }
                    </TrackVisibility>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-5">
                <div className="counter__item-4" data-aos="fade-up" data-aos-delay={900}>
                  <div className="counter__left-4">
                    <img src="assets/imgs/icon/c2.png" alt="Icon" />
                  </div>
                  <div className="counter__right-4">
                    <TrackVisibility once>
                      {({ isVisible }) =>
                        isVisible && (
                          <>
                            <div className="counter__number-4 counter_slow">
                              <CountUp delay={0} start={0} end={5} />
                            </div>
                            <h2 className="counter__title-4">Multifold verification</h2>
                          </>
                        )
                      }
                    </TrackVisibility>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Counter area end */}
    </>
  );
};

export default CounterFive;
