import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import AOS from "aos";
import ScrollToTop from "react-scroll-to-top";
import RouteScrollToTop from "./elements/RouteScrollToTop";
import HomeOne from "./pages/HomeOne";
import About from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import IndustriesWeFunctioned from "./pages/IndustriesWeFunctioned";
import Service from "./pages/service";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);

  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <Routes>
        <Route exact path="/" element={<HomeOne />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<ContactPage />} />
        <Route exact path="/industries-we-functioned" element={<IndustriesWeFunctioned />} />
        <Route exact path="/service" element={<Service />} />
      </Routes>
      <ScrollToTop smooth color="#009B7D" />
    </BrowserRouter>
  );
}

export default App;
