import React from "react";

const ServiceComponent = () => {
  return (
    <>
      {/* Service Details area start */}
      <section className="service__details-area pt-150">
        <div className="container">
          <div className="service__details top pb-140">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="img">
                  <img src="assets/imgs/service/a.jpg" alt="digibold" />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="content">
                  <h2 className="service__details-title">
                    Pre-event lead solutions:
                  </h2>
                  <ul class="list-angle">
                    <li>
                      Targeted Marketing Campaigns: Companies use targeted email
                      campaigns, social media ads, and other marketing channels
                      to promote their participation in upcoming events and
                      encourage attendees to visit their booth or attend their
                      session.
                    </li>
                    <li>
                      Event-Specific Landing Pages: Creating dedicated landing
                      pages for the event allows companies to capture leads'
                      information through registration forms, offering valuable
                      content or incentives in exchange for contact details.
                    </li>
                    <li>
                      Personalized Outreach: Sales and marketing teams conduct
                      personalized outreach to targeted prospects before the
                      event, inviting them to schedule meetings, demos, or
                      consultations during the event.
                    </li>
                    <li>
                      Networking and Engagement: Engaging with event attendees
                      on social media platforms and event-specific networking
                      forums helps companies build relationships and generate
                      buzz before the event, leading to increased attendance and
                      engagement.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="service__details mid pb-140">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="content">
                  <h2 className="service__details-title">
                    Post-event lead solutions:
                  </h2>
                  <ul class="list-angle">
                    <li>
                      Immediate Follow-Up: Companies follow up with event
                      attendees promptly after the event, sending thank-you
                      emails, providing additional resources or information
                      discussed during the event, and nurturing leads through
                      targeted follow-up communications.
                    </li>
                    <li>
                      Lead Scoring and Segmentation: Companies prioritize leads
                      based on their level of engagement and interest
                      demonstrated during the event, segmenting them into
                      different categories for personalized follow-up and
                      ongoing nurturing.
                    </li>
                    <li>
                      Content Marketing: Sharing event-related content such as
                      blog posts, whitepapers, or recorded sessions helps
                      companies stay top-of-mind with leads and provide
                      additional value post-event, further nurturing
                      relationships and driving conversions. Sales Outreach:
                      Sales teams engage in targeted outreach to follow up on
                      leads collected during the event, scheduling meetings,
                      demos, or consultations to move them further down the
                      sales funnel.
                    </li>
                    <li>
                      Measurement and Analysis: Companies track and analyze the
                      effectiveness of their pre and post-event lead generation
                      efforts, measuring metrics such as lead conversion rates,
                      pipeline velocity, and ROI to optimize future event
                      strategies.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="img">
                  <img src="assets/imgs/service/b.jpg" alt="digibold" />
                </div>
              </div>
              <div className="col-xxl-12">
                <p>
                  By implementing pre and post-event lead solutions effectively,
                  companies can maximize their event ROI, generate high-quality
                  leads, and build lasting relationships with prospects and
                  customers
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Service Details area end */}
      <section className="service__details-area ">
        <div className="container">
          <div className="service__details top pb-140">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="img">
                  <img src="assets/imgs/service/c.jpg" alt="Data cleansing" />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="content">
                  <h2 className="service__details-title">Data Cleansing</h2>
                  <p>
                    Data cleansing, also known as data scrubbing or data
                    cleaning, refers to the process of detecting and correcting
                    errors, inconsistencies, and inaccuracies in a dataset to
                    ensure its accuracy, reliability, and consistency. Here's
                    how data cleansing helps:
                  </p>
                  <ul class="list-angle">
                    <li>
                      Accuracy: Data cleansing removes duplicate records,
                      corrects misspellings, standardizes formats, and fills in
                      missing values, thereby improving the accuracy of the
                      dataset.
                    </li>
                    <li>
                      Consistency: By standardizing data formats and values,
                      data cleansing ensures consistency across the dataset,
                      making it easier to analyze and interpret.
                    </li>
                    <li>
                      Completeness: Data cleansing identifies and fills in
                      missing or incomplete information, ensuring that the
                      dataset is comprehensive and usable for analysis and
                      decision-making.
                    </li>
                    <li>
                      Compliance: Data cleansing helps ensure that the dataset
                      complies with relevant regulations and standards, such as
                      GDPR or industry-specific requirements, by removing
                      sensitive or outdated information.
                    </li>
                    <li>
                      Efficiency: Clean data leads to more efficient processes
                      and operations, as it reduces the time and effort required
                      for data analysis, reporting, and decision-making.
                    </li>
                    <li>
                      Improved Decision-Making: Clean and reliable data provides
                      a more accurate basis for decision-making, allowing
                      organizations to make better-informed strategic and
                      operational decisions.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xxl-12">
              <p>
                cleansing is a critical step in data management that helps
                organizations maintain the quality and integrity of their data
                assets, leading to improved efficiency, compliance, and
                decision-making.
              </p>
            </div>
          </div>
          <div className="service__details mid pb-140">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="content">
                  <h2 className="service__details-title">
                    Email Marketing Solutions
                  </h2>
                  <p>
                    Enhance and verify your datasets with our data appending and
                    validation services.
                  </p>
                  <h4>Data Appending</h4>
                  <p>
                    Data appending involves adding missing or incomplete
                    information to existing datasets. This could include
                    appending additional fields such as contact details,
                    demographic information, firmographics, or other relevant
                    data points.
                  </p>
                  <ul class="list-angle">
                    <li>
                      Email appending: Adding email addresses to records that
                      are missing them.
                    </li>
                    <li>
                      Phone appending: Adding phone numbers to records that lack
                      them.
                    </li>
                    <li>
                      Demographic appending: Adding demographic information such
                      as age, gender, income, or location to records.
                    </li>
                    <li>
                      Firmographic appending: Adding company-related information
                      such as industry, size, revenue, or location.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="img">
                  <img src="assets/imgs/service/d.jpg" alt="Email Marketing Solutions" />
                </div>
              </div>
              <div className="col-xxl-12">
                <h4>Data Validation</h4>
                <p>
                  Data validation involves verifying the accuracy, consistency,
                  and completeness of existing data within a dataset.
                </p>

                <ul class="list-angle">
                  <li>
                    Address validation: Ensuring that postal addresses are
                    accurate and standardized.
                  </li>
                  <li>
                    Email validation: Verifying the authenticity and
                    deliverability of email addresses.
                  </li>
                  <li>
                    Phone number validation: Checking the validity and format of
                    phone numbers.
                  </li>
                  <li>
                    Duplicate detection: Identifying and removing duplicate
                    records within a dataset.
                  </li>
                  <li>
                    Data standardization: Ensuring consistent formatting and
                    structure of data fields.
                  </li>
                </ul>
                <p>
                  Data validation helps improve data quality, reliability, and
                  integrity, ensuring that organizations can trust their data
                  for decision-making and operational purposes.
                </p>
                <p>
                  Overall, data appending and validation are critical components
                  of data management that help organizations maintain accurate,
                  complete, and up-to-date datasets, ultimately enabling better
                  decision-making, enhanced customer experiences, and improved
                  operational efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceComponent;
