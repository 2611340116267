import React, { useState } from "react";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_phone: "",
    msg: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      service_id: process.env.REACT_APP_SERVICE_ID,
      template_id: process.env.REACT_APP_TEMPLATE_ID,
      user_id: process.env.REACT_APP_USER_ID,
      template_params: {
        user_name: formData.user_name,
        user_email: formData.user_email,
        user_phone: formData.user_phone,
        msg: formData.msg,
      },
    };

    axios
      .post("https://api.emailjs.com/api/v1.0/email/send", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        alert("Your mail is sent!");
      })
      .catch((error) => {
        alert("Oops... " + JSON.stringify(error));
      });
  };

  return (
    <>
      <section className="contact__area-6 pb-150">
        <div className="container">
          <div className="blog__form">
            <span className="blog__form-title">Contact Us</span>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="user_name"
                    id="Name"
                    placeholder="Name"
                    value={formData.user_name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    name="user_email"
                    id="E-mail"
                    placeholder="E-mail"
                    value={formData.user_email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="tel"
                    name="user_phone"
                    id="Phone"
                    placeholder="Phone"
                    value={formData.user_phone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <textarea
                    name="msg"
                    id="msg"
                    placeholder="Message"
                    value={formData.msg}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="contact__submitwrap">
                  <button className="contact__submit btn-rollover" type="submit">
                    Send now <i className="fa-solid fa-arrow-right" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
