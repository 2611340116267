import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      {/* About Us Details area start */}
      <section className="aboutnew__details-area pt-150">
        <div className="container">
          <div className="aboutnew__details top pb-140">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="img">
                  <img src="assets/imgs/about/a.jpg" alt="Smart Itech LLC" />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="content">
                  <h2 className="aboutnew__details-title">
                    About Smart Itech LLC
                  </h2>
                  <p>
                    At Smart Itech LLC, we partner with innovative enterprises and independent software vendors to enhance their technological capabilities, streamline solutions, and manage specialized projects. Our mission is to empower businesses through cutting-edge technology and seamless integration into their workflows. We are a customer-focused team driven by innovation, superior deployment capabilities, and a commitment to excellence. Smart Itech LLC is your trusted partner in achieving business objectives across various technology landscapes.
                  </p>
                  <p>
                    Our tagline, “Modernization Made Simple,” reflects our dedication to simplifying the complex process of modernizing and managing technology.
                  </p>
                  <Link className="db-btn-primary btn-rollover" to="/service">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutnew__details mid pb-140">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="content">
                  <h2 className="aboutnew__details-title">
                    Our Expertise
                  </h2>
                  <p>
                    We specialize in delivering distributed, scalable, and highly available solutions tailored to enterprise needs. Our team excels at designing and implementing robust ecosystems that support organizational growth and can handle sudden traffic surges. We focus on creating cost-effective, compliant systems that align with organizational standards, ensuring secure access to private resources in public cloud environments.
                  </p>
                  <div className="feature">
                    <div className="icon">
                      <img style={{ border: 'none' }} src="assets/imgs/icon/phone.png" alt="Icon" />
                    </div>
                    <div className="info">
                      <h4 className="title">Infrastructure Management</h4>
                      <p>
                        In today’s fast-paced environment, staying current with infrastructure trends and upgrades is essential. Our team is adept at managing servers and infrastructure for client resources in data centers, making the modernization of legacy applications and the development of cloud-native applications straightforward and efficient.
                      </p>
                    </div>
                  </div>
                  <div className="feature">
                    <div className="icon">
                      <img style={{ border: 'none' }} src="assets/imgs/icon/phone.png" alt="Icon" />
                    </div>
                    <div className="info">
                      <h4 className="title">Automation and Orchestration</h4>
                      <p>
                        Our expertise includes infrastructure automation using Terraform and Ansible, as well as container orchestration with Kubernetes. We have extensive experience in migrating workloads and data from on-premises to public cloud platforms, providing end-to-end solutions tailored to our customers' needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="img">
                  <img src="assets/imgs/about/b.jpg" alt="Infrastructure Management" />
                </div>
              </div>
              <div className="col-xxl-12">
                <p>
                  Partner with Smart Itech LLC to unlock new opportunities for your business. Our data-driven strategies and technological solutions are designed to provide measurable results, helping you achieve your business objectives efficiently. Contact us today to learn more about how we can support your technology and business growth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Us Details area end */}
    </>
  );
};

export default About;
